<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      color="success"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-file-document-box-multiple-outline</v-icon> Управление контентом сайта
        </div>
      </template>
      <template #after-heading>
        <v-btn
          color="success"
          @click="addNew"
        >
          Добавить
        </v-btn>
      </template>
      <v-row
        v-if="dataLoading"
        justify="center"
        style="height: 140px"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="light-green lighten-2"
          indeterminate
        />
      </v-row>
      <v-row
        v-else
        class="mt-3"
        style="min-height: 400px"
      >
        <v-col cols="3">
          <template>
            <v-list>
              <v-list-group
                v-for="site in contents"
                :key="'site-' + site.id"
                :prepend-icon="'mdi-'+ site.image"
              >
                <template #activator>
                  <v-list-item-title @click="hideContent()">
                    {{ site.name }}
                  </v-list-item-title>
                </template>
                <v-list-group
                  v-for="page in site.children"
                  :key="'page-' + page.page_id"
                  v-model="page.active"
                  sub-group
                  no-action
                >
                  <template #activator>
                    <v-list-item-icon>
                      <v-icon>mdi-book-open-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content @click="showPageMeta(page)">
                      <v-list-item-title>{{ page.page_name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-group
                    v-for="section in page.children"
                    :key="'section-' + section.section_id"
                    sub-group
                    color="primary"
                    @click="hideContent()"
                  >
                    <template #activator>
                      <v-list-item-icon>
                        <v-icon>mdi-comment-text-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ section.section_title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="content in section.children"
                      :key="'content-' + content.cont_id"
                      sub-group
                      :class="content.active?'v-list-item--active':''"
                      @click="[showsect(content), content.active = !content.active]"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-comment-text-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-text="content.content_title" />
                    </v-list-item>
                  </v-list-group>
                </v-list-group>
              </v-list-group>
            </v-list>
          </template>
        </v-col>
        <v-divider vertical />
        <v-col>
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!(content.content_title || pageMeta.active)"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center;"
            >
              Выберете контент
            </div>
            <v-card
              v-else-if="pageMeta.active"
              flat
            >
              <v-text-field
                v-model="pageMeta.page_name"
                :rules="nameRules"
                label="Страница"
                class="pb-1 pt-4"
                readonly
                required
              />
              <v-text-field
                v-model="pageMeta.title"
                :rules="nameRules"
                label="Заголовок страницы"
                class="pb-1 pt-4"
                counter="128"
                required
              />
              <v-textarea
                v-model="pageMeta.metatitle"
                outlined
                counter="128"
                :rules="nameRules"
                label="Описание страницы"
                class="pb-1 pt-4"
                required
              />
              <v-textarea
                v-model="pageMeta.metatags"
                outlined
                counter="256"
                :rules="nameRules"
                label="Мета теги"
                class="pb-1 pt-4"
                required
              />
              <v-btn
                :disabled="!canSaveMeta"
                :loading="dataUpdating"
                @click="updateMeta(pageMeta)"
              >
                Обновить
              </v-btn>
            </v-card>
            <v-card
              v-else
              flat
            >
              <v-text-field
                v-model="content.content_title"
                :rules="nameRules"
                label="Заголовок"
                class="pb-1 pt-4"
                required
              />
              <tiptap-vuetify
                v-model="content.content"
                :extensions="extensions"
              />
              <v-checkbox
                v-model="allowedCont"
                label="Доступен"
              />
              <v-col>
                <v-btn
                  :disabled="!canSave"
                  :loading="dataUpdating"
                  @click="updateContlite(content)"
                >
                  Обновить
                </v-btn>
              </v-col>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      ref="dialog"
      v-model="popup"
      persistent
      scrollable
      width="890px"
    >
      <v-form ref="contentform">
        <v-card>
          <v-card-title>
            <v-spacer />
            <v-icon
              aria-label="Close"
              @click="popup = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text class="text-body-1">
            <div class="pa-3">
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex"
                >
                  <v-select
                    v-model="content.site_id"
                    :items="sites"
                    item-value="id"
                    item-text="title"
                    label="Cайт"
                    outlined
                    :disabled="content.cont_id?true:false"
                    class="mr-3"
                    required
                  />
                  <v-select
                    v-model="content.sectionid"
                    :items="siteSections"
                    item-value="section_id"
                    item-text="section_title"
                    label="Раздел"
                    :disabled="content.cont_id?true:false"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="content.content_title"
                    :rules="nameRules"
                    label="Заголовок"
                    class="pb-1 pt-4"
                    required
                  />
                </v-col>
              </v-row>
              <tiptap-vuetify
                v-model="content.content"
                :extensions="extensions"
              />
            </div>
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer />
            <v-btn
              color="error"
              depressed
              default
              text
              @click="popup = false"
            >
              отменить
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              depressed
              default
              text
              @click="updateContent(content)"
            >
              сохранить
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Image } from 'tiptap-vuetify'
  // import FileSelector from '~/Components/FileSelector'

  export default {
    // specify TiptapVuetify component in "components"
    components: { TiptapVuetify },
    data: () => ({
      dataLoading: false,
      contents: [],
      dataUpdating: false,
      selectedItem: null,
      treeitems: [],
      sections: [],
      pages: [],
      pageMeta: {
        active: false,
      },
      pageMetaSnap: {},
      linkedContent: {},
      content: {
        cont_id: null,
        content: null,
        content_title: null,
        datedate: null,
        page_name: null,
        section_title: null,
        site_id: null,
        site_name: null,
        pageid: null,
      },
      contentSelected: null,
      contentItemsPerPage: 10,
      siteFilter: null,
      tableLoading: true,
      siteContent: [],
      popup: false,
      sites: [],
      title: '',
      selectedSites: [],
      items: [
        'main',
        'news',
      ],
      // declare extensions you want to use
      nameRules: [
        v => !!v || 'Необходимо заполнить заголовок',
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        Image,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        Link,
        Code,
        HorizontalRule,
        HardBreak,
      ],
      // starting editor's content
    }),
    computed: {
      allowedCont: {
        get () {
          return !!this.content.allowed
        },
        set (val) {
          this.content.allowed = val ? 1 : 0
        },
      },
      allowedPage: {
        get () {
          return !!this.pageMeta.flgactive
        },
        set (val) {
          this.pageMeta.flgactive = val ? 1 : 0
        },
      },
      siteSections () {
        return this.sections.filter(value => value.siteid === this.content.site_id)
      },
      headersContent () {
        return [
          {
            text: ' Сайт',
            align: 'center',
            value: 'site_name',
            width: '10%',
            sortable: false,
          },
          {
            text: ' (страница)Раздел',
            align: 'left',
            value: 'sectionname',
            width: '10%',
            sortable: false,
          },
          {
            text: ' Заголовок',
            align: 'left',
            value: 'content_title',
            width: '10%',
            sortable: false,
          },
          {
            text: ' Контент',
            align: 'left',
            value: 'content',
            width: '70%',
            sortable: false,
          },
        ]
      },
      allSites () {
        return this.selectedSites.length === this.sites.length
      },
      someSites () {
        return this.selectedSites.length > 0 && !this.allSites
      },
      icon () {
        if (this.allSites) return 'mdi-close-box'
        if (this.someSites) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      canSaveMeta () {
        return this.pageMetaSnap.title !== this.pageMeta.title ||
          this.pageMetaSnap.metatags !== this.pageMeta.metatags ||
          this.pageMetaSnap.metatitle !== this.pageMeta.metatitle
      },
      canSave () {
        return this.linkedContent.allowed !== this.content.allowed || this.linkedContent.content_title !== this.content.content_title || this.linkedContent.content !== this.content.content
      },
    },
    mounted () {
      this.dataLoading = true
      Promise.all([
        this.$store.dispatch('getWSsites'),
        this.$store.dispatch('getWSpages'),
        this.$store.dispatch('getWSsections'),
        this.$store.dispatch('getWScontent')]).then(([sites, pages, section, content]) => {
          this.siteContent = JSON.parse(JSON.stringify(content))
          this.sites = sites
          this.sections = section
          this.sites.forEach(site => {
            const sitepg = pages.filter(page => page.siteid === site.id)
            sitepg.forEach((page) => {
              const sitesec = section.filter(section => section.page_id === page.page_id)
              sitesec.forEach((sect) => {
                const sitecont = content.filter(cont => sect.section_id === cont.sectionid)
                sect.children = sitecont
              })
              page.children = sitesec
            })
            this.contents.push({ id: site.id, name: site.title, image: 'web', children: sitepg })
            this.dataLoading = false
          })
        })
      // this.$store.dispatch('getWSsections').then((resp) => {
      //   resp.forEach((x) => {
      //     x.pgsec = x.page_name + '/' + x.section_title
      //   })
      //   const result = []
      //   const map = new Map()
      //   for (const item of resp) {
      //     if (!map.has(item.pageid)) {
      //       map.set(item.pageid, true)
      //       result.push({
      //         pageid: item.page_id,
      //         page_name: item.page_name,
      //       })
      //     }
      //   }
      //   this.pages = result
      //   this.sections = resp
      // })
      // this.$store.dispatch('getWScontent').then((resp) => {
      //   this.list_to_tree([...resp])
      //   this.siteContent = JSON.parse(JSON.stringify(resp))
      //   this.tableLoading = false
      // })
    },
    methods: {
      hideContent () {
        this.content = {
          cont_id: null,
          content: null,
          content_title: null,
          datedate: null,
          page_name: null,
          section_title: null,
          site_id: null,
          site_name: null,
          pageid: null,
          active: false,
        }
        this.pageMeta = {
          active: false,
        }
        this.linkedContent.active = false
      },
      showPageMeta (pageid) {
        this.content = {
          cont_id: null,
          content: null,
          content_title: null,
          datedate: null,
          page_name: null,
          section_title: null,
          site_id: null,
          site_name: null,
          pageid: null,
          active: false,
        }
        this.pageMeta = pageid
        this.pageMetaSnap = JSON.parse(JSON.stringify(pageid))
        // this.pageMeta.active = true
        this.linkedContent.active = false
      },
      showsect (item) {
        this.content.active = false
        this.linkedContent.active = false
        this.content = { content_title: null }
        this.linkedContent = item
        this.pageMeta = {
          active: false,
        }
        setTimeout(() => {
          this.content = JSON.parse(JSON.stringify(item))
        }, 100)
      },
      striptext (html) {
        return html.replace(/<style([\s\S]*?)<\/style>/gi, ' ')
          .replace(/<script([\s\S]*?)<\/script>/gi, ' ')
          .replace(/(<(?:.|\n)*?>)/gm, ' ')
          .replace(/\s+/gm, ' ')
      },
      addNew () {
        this.content = {
          cont_id: null,
          content: null,
          content_title: null,
          datedate: null,
          page_name: null,
          section_title: null,
          sectionid: null,
          site_id: null,
          site_name: null,
        }
        if (this.sites.length === 1) { this.content.site_id = this.sites[0].id }
        this.popup = true
      },
      updateMeta (item) {
        this.dataUpdating = true
        this.$store.dispatch('updateWSarticle', item).then(() => {
          this.pageMetaSnap = JSON.parse(JSON.stringify(item))
          this.$store.dispatch('setSnackbar', { state: true, text: 'Данные обновлены', color: 'success', direction: 'top center' })
          this.popup = false
          this.dataUpdating = false
        }).catch((e) => {
          this.dataUpdating = false
        })
      },
      updateContlite (item) {
        if (item.cont_id) {
          this.dataUpdating = true
          this.$store.dispatch('updateWScontent', item).then(() => {
            this.linkedContent.content_title = item.content_title
            this.linkedContent.content = item.content
            this.$store.dispatch('setSnackbar', { state: true, text: 'Запись обновлена', color: 'success', direction: 'top center' })
            this.popup = false
            this.dataUpdating = false
          }).catch((e) => {
            this.dataUpdating = false
          })
        }
      },
      updateContent (item) {
        if (this.$refs.contentform.validate()) {
          if (this.content.cont_id) {
            this.$store.dispatch('updateWScontent', item).then((resp) => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Запись обновлена', color: 'success', direction: 'top center' })
              this.popup = false
            })
          } else {
            this.$store.dispatch('setWScontent', item).then((resp) => {
              if (resp.result > 0) {
                this.$store.dispatch('setSnackbar', { state: true, text: 'Запись добавлена', color: 'success', direction: 'top center' })
                this.popup = false
                this.content.cont_id = resp.result
                this.content.cont_id = resp.pageid
                this.siteContent.unshift(this.content)
                this.content = {
                  cont_id: null,
                  content: null,
                  content_title: null,
                  datedate: null,
                  page_name: null,
                  section_title: null,
                  site_id: null,
                  site_name: null,
                }
              }
            })
          }
        }
      },
      selectContent (item) {
        this.content = item
        this.popup = true
      },
      toggle () {
        this.$nextTick(() => {
          if (this.allSites) {
            this.selectedSites = []
          } else {
            this.selectedSites = this.sites.slice()
          }
        })
      },
    },
  }
</script>
<style>
  .tiptap-vuetify-editor__content{
    min-height: 200px;
    padding: 10px;
  }
</style>
